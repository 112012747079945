// Dependencies
import React, { Component } from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { Grid, Row, Col, Box, Typography } from '@smooth-ui/core-sc';

// Components
import Mask from 'components/Mask';
import Slider from 'components/Slider';
import Layout from 'components/Layout';
import Link from 'components/Link';
import Button from 'components/Button';
import SEO from 'components/seo';
import Card from 'components/Card';
import Contact from 'components/Forms/Contact';

import UiContext from 'context/UiContext';

// Utils
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from 'lib/helpers';
import { dedupe, numberWithCommas } from 'utils/helpers';

export const query = graphql`
  query IndexQuery {
    page: sanityHome {
      title
      copy {
        style
        _type
        style
        children {
          _key
          _type
          text
        }
      }
      image {
        asset {
          small: fixed(toFormat: JPG, width: 800, height: 960) {
            src
          }
          large: fixed(toFormat: JPG, width: 553, height: 886) {
            src
          }
        }
      }
      spotlight {
        title
        properties {
          id
          slug {
            current
          }
          price
          location {
            city
            zip
          }
          photos {
            asset {
              fluid(toFormat: JPG, maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      contact {
        title
        image {
          asset {
            fixed(toFormat: JPG, width: 565, height: 750) {
              src
            }
          }
        }
      }
      seo {
        title
        description
        image {
          asset {
            fixed(toFormat: JPG, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
    # Used in case there are less than 3 spotlight items

    allSanityProperty(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { ne: "archived" } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          price
          location {
            city
            zip
          }
          photos {
            asset {
              fluid(toFormat: JPG, maxWidth: 600) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default class IndexPage extends Component {
  render() {
    const { data } = this.props;
    const { isIE } = this.context;
    const { page, allSanityProperty } = data;

    const fallbackProperties = mapEdgesToNodes(allSanityProperty).filter(filterOutDocsWithoutSlugs);
    const spotlightProperties = page.spotlight.properties || [];

    // Merge both collections and remove duplicates
    const deduped = dedupe([...spotlightProperties, ...fallbackProperties], 'id');

    // Get the properties that are not in the spotlight
    const fallbacks = deduped.filter(
      property => spotlightProperties && !spotlightProperties.includes(property)
    );

    // Find out how many of the fallbacks need to be shown
    const maxLength = 3;
    const fallbacksNeeded = maxLength - spotlightProperties.length;
    const remaining = fallbacks.slice(0, fallbacksNeeded);

    const spotlightItems = spotlightProperties.length
      ? [...spotlightProperties, ...remaining]
      : remaining;

    return (
      <Layout>
        <SEO
          title={page.seo.title}
          description={page.seo.description}
          image={page.seo.image.asset.fixed.src}
        />

        <Box mb={{ xs: 6, md: 18 }}>
          <Grid>
            <Row>
              <Col xs={12} md={6}>
                <Box
                  mt={'-40%'}
                  mb={{ xs: 50, md: 0 }}
                  ml={{ xs: '-20px', sm: 0 }}
                  height={isIE ? '100%' : 'auto'}
                >
                  <Mask large={page.image.asset.large.src} small={page.image.asset.small.src} />
                </Box>
              </Col>
              <Col mt={{ md: 120 }} xs={12} md={6}>
                <Box>
                  <Typography variant="h1" mb={50}>
                    {page.title}
                  </Typography>
                  {page.copy.map((item, i) => (
                    <Typography key={i} as="p" mb={4} fontSize={16}>
                      {item.children[0].text}
                    </Typography>
                  ))}
                  <Button as={Link} to="/over-ons">
                    Meer over ons
                  </Button>
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>

        <Box
          maxWidth={{ xs: 1, md: 1190 }}
          width={1}
          mx="auto"
          // px={{ xs: 0, md: '2rem' }}
          mb={{ xs: 6, md: 18 }}
          overflow="hidden"
        >
          <Typography textAlign="center" variant="h1" fontSize={35} mb={{ xs: 4, lg: 6 }}>
            {page.spotlight.title}
          </Typography>
          <Box textAlign="center" mb={{ xs: 3, md: 6 }}>
            <Slider
              slidesToShow={3}
              className="carousel__slider--spotlight"
              arrows={false}
              infinite={false}
              swipeToSlide={true}
              draggable={false}
              responsive={[
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]}
            >
              {spotlightItems.map((property, i) => (
                <div key={i}>
                  <Card mx={{ xs: '5px', md: 2 }}>
                    <Link to={`/vastgoed/${property.slug.current}`} style={{ display: 'block' }}>
                      <Img fluid={{ ...property.photos[0].asset.fluid, aspectRatio: 5 / 4.25 }} />
                      <Box
                        p={3}
                        backgroundColor="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography fontSize={3} fontWeight="bold">{`€ ${numberWithCommas(
                          property.price
                        )}`}</Typography>
                        <Box display={{ xs: 'block', md: 'none', lg: 'block' }}>
                          <Typography>
                            {property.location.zip}, {property.location.city}
                          </Typography>
                        </Box>
                        <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
                          <Typography>{property.location.zip}</Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Card>
                </div>
              ))}
            </Slider>
          </Box>
          <Box textAlign="center">
            <Button as={Link} to="/vastgoed">
              Ontdek onze panden
            </Button>
          </Box>
        </Box>
        <Contact
          formName="Home pagina"
          title={page.contact.title}
          image={page.contact.image.asset.fixed.src}
        />
      </Layout>
    );
  }
}

IndexPage.contextType = UiContext;
